import React from "react";
import Select from "../../../../components/AntSelect";
import DateRangePicker from "../../../../components/DatePicker";
import ReactSelect from "../../../../components/reactSelect/reactSelect";

/**
 * @Author Jai
 * @Date 14 April, 2021
 * @Description Created this drop down filter page for filtring the Country, City and Zone name, and we are using this page on Ride Analytics Overview.
 */

const Filter = (props) => {
  return (
    <div className="d-flex w-100">
      <div className="col-2 pl-0">
        <label className="">Country</label>
        <Select
          handleSelectChange={(id, key) => props.selectHandle(id, "countries")}
          data={props.countries}
          type="countries"
          selected={props.country}
          placeholder="select country"
        ></Select>
      </div>
      <div className="col-2">
        <label className="">City</label>
        <Select
          handleSelectChange={(id, key) => props.selectHandle(id, "cities")}
          data={props.cities}
          type="gread"
          placeholder="select city"
          selected={props.city}
        ></Select>
      </div>
      {/* <div className="col-2">
        <label className="">Zone</label>
        <Select
          handleSelectChange={(id, key) => props.selectHandle(id, "zones")}
          data={props.zones}
          type="section"
          placeholder="select zone"
          selected={props.zone}
        ></Select>
      </div> */}

      {!props.hideGroupBy ? (
        <div className="col-0">
          <label className="">Group By</label>
          <ReactSelect
            className={false}
            city={props.groupByselectData}
            change={props.groupByselectHandler}
            selected={props.groupByselected}
          />
        </div>
      ) : (
        ""
      )}

      <div className="col-2">
        <label className="">Date Range</label>
        <DateRangePicker
          getGroupBy={true}
          handleDateChange={props.handleDateChange}
        />
      </div>
    </div>
  );
};

export default Filter;
