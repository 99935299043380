import React, { Component } from "react";
import {
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Row from "./row";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
class TableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      rowsPerPage: 10,
      page: 0,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
    });
  };

  render() {
    const { tableData } = this.props;
    const { open } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead style={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell style={{ fontWeight: 700, fontSize: "16px" }}>
                  Month
                </TableCell>

                <TableCell
                  style={{ fontWeight: 700, fontSize: "16px" }}
                  align="right"
                >
                  Normal Fare
                </TableCell>
                <TableCell
                  style={{ fontWeight: 700, fontSize: "16px" }}
                  align="right"
                >
                  Surge Fare
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
                .map((row, index) => (
                  <Row
                    row={row}
                    key={index}
                    collapse={this.state.open}
                    currency={this.props.currencyData}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ backgroundColor: "white" }}
          rowsPerPageOptions={[5, 10, 15]}
          id="backgroundShadow"
          component="div"
          count={tableData.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default TableComponent;
