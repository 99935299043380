import React, { Component } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

// MUI Icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { isFloat, tooltipFormatter } from "../../../../../lib/helper";

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      // history: [
      //   { callType: "In Call", cardPayment: 9, cashPayment: 8 },
      //   { callType: "Out Call", cardPayment: 9, cashPayment: 8 },
      //   { callType: "Tele Call", cardPayment: 9, cashPayment: 8 },
      // ],
    };
  }
  render() {
    const { open } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            style={{ fontWeight: 550, fontSize: "13px" }}
          >
            {row.timeStamp}
          </TableCell>
          <TableCell
            align="right"
            style={{ fontWeight: 550, fontSize: "13px" }}
          >
            {`${tooltipFormatter(
              isFloat(row.fare) ? row.fare.toFixed(2) : row.fare,
              this.props.currency,
              true
            )}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse
              in={this.props.collapse ? true : open}
              timeout="auto"
              unmountOnExit
            >
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Vehicle Type</TableCell>
                      <TableCell align="right">Fare</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow, index) => (
                      <TableRow key={`id${index}`}>
                        <TableCell component="th" scope="row">
                          {historyRow["Vehicle Type"]}
                        </TableCell>
                        <TableCell align="right">
                          {`${tooltipFormatter(
                            historyRow.fare,
                            this.props.currency,
                            true
                          )}`}
                          {/* {this.props.currency} {historyRow.fare} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

export default Row;
