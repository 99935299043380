// import { get } from "../lib/request";
import { getForReport } from "../lib/request";

// Ride Count Analytics
export const fetchTopRides = (
  start_time,
  end_time,
  // groupBy,
  sortBy,
  device,
  top,
  currency,
  country_id,
  city_id,
  zone_id
) => {
  let URL = `/ride/top/location?&start_timestamp=${start_time}&end_timestamp=${end_time}&sort=${sortBy}&top=${top}&device_type=${device}&currency=${currency}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

// export const storeIdApiCall = () => {
//   return getForReport("/python-spark/store-category");
// };
