import React, { Component } from "react";
import "../../../../static/scss/globalComponent.scss";
import "./rideSurge.css";
import moment from "moment";
import Snackbar from "../../../../components/snackbar/snackbar";
import Graph from "./graph";
import ReactToPrint from "react-to-print";
import Modal from "../../../../components/modal/modal";
import Pbutton from "../../../../components/button/Button";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import TableComponent from "./table/table.js";
import {
  getCities,
  getCountries,
} from "../../../../services/rideLocationFilter";
import _ from "lodash";
import Filters from "../location/filter";
import {
  dateTimeFormatForGraph,
  setInLocalStorage,
  tooltipFormatter,
} from "../../../../lib/helper";
import { fetchRideSurge } from "../../../../services/RideSurge";

import {  Menu, MenuItem } from "@material-ui/core";
import Input from "../../../../components/InputBox/Input";
import { countries } from "../../../../fixtures/dummyApi/countries";

/**
 * @Author Jai
 * @Date 19 April, 2021
 * @Description Create a new Page for surge chart and added a new functionalities in this page like country, city, zone, and device type filter
 */

class RideSurge extends Component {
  myRef = React.createRef();
  state = {
    loader: true,
    snackBarMessage: "",
    showSnackbar: false,
    tableData: [],
    tableLoader: true,
    isExport: false,
    isMoreThanThousand: false,
    isDisplayChart: false,
    noDataText: "Loading Data ...",

    sortByDevice: [
      { label: "All", value: 0 },
      { label: "Android", value: 1 },
      { label: "iOS", value: 2 },
    ],
    selectedSortByDevice: { label: "All", value: 0 },
    dataLoaded: false,
    logsData: [],

    country: "",
    city: "",
    countryId: "",
    cityId: "",
    countries: [],
    cities: [],

    date: {
      startDate:
        localStorage.getItem("startDate") != null
          ? localStorage.getItem("startDate")
          : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate:
        localStorage.getItem("endDate") != null
          ? localStorage.getItem("endDate")
          : moment().format("YYYY-MM-DD HH:mm:ss"),
    },

    selected: localStorage.getItem("selectedGroupBy")
      ? JSON.parse(localStorage.getItem("selectedGroupBy"))
      : { value: 0, label: "Hour" },
    selectData: [{ value: 0, label: "Hour" }],

    // currency
    // currencyData: localStorage.getItem("currency")
    //   ? JSON.parse(localStorage.getItem("currency"))
    //   : "INR",
    // previousCurrencyData: localStorage.getItem("currency")
    //   ? JSON.parse(localStorage.getItem("currency"))
    //   : "INR",

    currencyData: window._env_.CURRENCY,
    previousCurrencyData: window._env_.CURRENCY,

    filterData: [...countries],
    anchorEl: null,
    currencyFilter: {
      label: "Currency filter",
      input: {
        name: "currencyFilter",
        type: "text",
        value: "",
      },
    },
  };

  componentDidMount() {
    this.handleFilterData("", "initialCall");
    this.intermediate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currencyData !== prevState.currencyData ||
      prevState.selectedSortByDevice.value !==
        this.state.selectedSortByDevice.value ||
      this.state.countryId !== prevState.countryId ||
      this.state.cityId !== prevState.cityId ||
      this.state.date.startDate !== prevState.date.startDate ||
      this.state.date.endDate !== prevState.date.endDate ||
      this.state.selected.value !== prevState.selected.value
    ) {
      setInLocalStorage("currency", this.state.currencyData);

      this.setState({
        tableLoader: true,
        isDisplayChart: false,
        noDataText: "Loading Data ...",
      });
      this.intermediate();
    }

    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],
      });
    }
  }

  intermediate = () => {
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.currencyData,
      this.state.selectedSortByDevice.value,
      this.state.countryId,
      this.state.cityId,
    );
  };
  getData = (
    start_timestamp,
    end_timestamp,
    selectedGroupById,
    currency,
    device,
    countryId,
    cityId,
  ) => {
    fetchRideSurge(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      selectedGroupById || 0,
      currency,
      device,
      countryId || "",
      cityId || "",
    )
      .then((response) => {
        let arrayCollection = [];
        let exportData = [];
        let data = {};
        let history = [];
        let xCategories = dateTimeFormatForGraph(
          this.state.selected.label || "Hour",
          response.data.data.graph.xaxis.categories
        );

        let isThousandData = response.data.data.show_message ? true : false;
        // let numberOfDecimal =
        //   Math.max(...response.data.graph.series[0].data) <= 3 ? 1 : 0;

        response.data.data.graph.xaxis.categories = [...xCategories];
        response.data.data.table.totalAmount["Time Line"] = [...xCategories];
        response.data.data.table.totalAmount["Normal Fare"].map(
          (item, index) => {
            Object.keys(response.data.data.history).map((data, dataIndex) => {
              if (
                this.state.selected.value === 3 ||
                this.state.selected.value === 7
              ) {
                if (
                  data ===
                  response.data.data.table.totalAmount["Time Line"][index]
                ) {
                  history = response.data.data.history[data];
                }
              } else {
                if (index === dataIndex) {
                  history = response.data.data.history[data];
                }
              }
              return history;
            });
            data = {
              normalFare: item,
              surgeFare:
                response.data.data.table.totalAmount["Surge Fare"][index],
              timeStamp:
                response.data.data.table.totalAmount["Time Line"][index],
              history,
            };

            return arrayCollection.push(data);
          }
        );
        arrayCollection.map((object) =>
          object.history.map((historyObject) =>
            exportData.push({
              timeStamp: object.timeStamp,
              "Normal_Fare": object["Normal Fare"],
              "Surge_Fare": object["Surge Fare"],
              "Vehicle Type": historyObject["Vehicle Type"],
              "Normal Fare": historyObject.normalFare,
              "Surge Fare": historyObject.surgeFare,
            })
          )
        );

        let graphData = {
          series: [...response.data.data.graph.series],
          options: {
            plotOptions: { bar: { columnWidth: "30%" } },
            title: {
              text: "",
              align: "left",
              style: { fontSize: "18px", fontWeight: 500 },
            },
            dataLabels: { enabled: false },
            chart: {
              width: "90%",
            },
            xaxis: {
              type: "category",
              // offsetX:10,
              categories: [...response.data.data.graph.xaxis.categories],
              title: {
                text: response.data.data.graph.xaxis.title,
                style: {
                  fontSize: "11px",
                  fontWeight: 700,
                  paddingRight: "20px",
                },
              },

              tickPlacement: "on",
              tooltip: {
                enabled: false,
              },
            },
            grid: {
              padding: {
                left: 40,
              },
            },
            legend: {
              position: "top",
              showForSingleSeries: true,
            },
            yaxis: {
              title: {
                text: response.data.data.graph.yaxis.title,
                style: { fontSize: "11px", fontWeight: 700 },
              },
              opposite: false,
              labels: {
                offsetX:20,
                show: true,
                style: { fontSize: "11px" },
                // formatter: function (value) {
                //   return parseInt(value);
                // },

                formatter: (val, index) => {
                  return tooltipFormatter(val, this.state.currencyData, false);
                },  
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
              shared: true,
              y: {
                formatter: (val, series) => {
                  return tooltipFormatter(val, this.state.currencyData, true);
                },
                title: {
                  formatter: (seriesName) => seriesName,
                },
              },
            },
          },
        };

        this.setState({
          dataLoaded: true,
          loader: false,
          graphData,
          tableLoader: false,
          isMoreThanThousand: isThousandData,
          isDisplayChart: true,
          tableData: arrayCollection,
          logsData: exportData,
          noDataText: "",
        });
      })
      .catch((error) => {
        if (!this.state.date.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Data Not Found For the Given Date Range!",
          });
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            dataLoaded: false,
            loader: false,
            tableLoader: false,
            tableData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            tableLoader: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };

  sortSelectHandler = (option) => {
    if (option.value === 0 || option.value === 1 || option.value === 2) {
      this.setState({
        selectedSortByDevice: option,
      });
    }
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
      }
       
      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState({
      date: {
        startDate: startDate,
        endDate: endDate,
      },
      selectData: groupBy,
      selected: selectedGroupBy,
      loader: true,
    });
  };

  selectHandler = (option) => {
    this.setState({ selected: option, loader: true });
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });
    // let filterData = countries.filter((item, index) => {
    //   return (
    //     item.alpha3Code.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
    //       -1 ||
    //     item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    //   );
    // });

    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase()) )  
       !==
          -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      filterData,
    });
  };

  getCurrencyData = (obj) => {
    this.setState({
      currencyData: obj.currencies[0].code,
    });
    this.handleClose();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { country, city } = this.state;
    return (
      <React.Fragment>
        <div className="containerDiv" ref={this.myRef}>
          <div className="">
            <div
              className="hoverPathClass globalFontSize"
              onClick={() =>
                this.props.history.push("rides-analytics-overview")
              }
            >
              <i className="fas fa-angle-left mr-2"></i>
              Reports
            </div>
            <div className="title">Ride Surge</div>
            <div className="d-flex text-grey mt-2" style={{ marginBottom: "" }}>
              <div className="mr-3 db_ptr">
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <i className="fas fa-print mr-1"></i>
                      Print
                    </span>
                  )}
                  content={() => this.myRef.current}
                />
              </div>
              <div
                className="mr-3 db_ptr"
                onClick={() =>
                  this.setState({
                    isExport: !this.state.isExport,
                  })
                }
              >
                <i className="fas fa-download mr-1"></i>
                Export
              </div>
            </div>
            <div
              className="moreThanThousand"
              style={{
                display: this.state.isMoreThanThousand ? "" : "none",
              }}
            >
              <div className="iconDiv">
                <span className="iconSpan"></span>
              </div>
              <div className="bannerContent">
                This report shows up to 1,000 results. To see all results, you
                can
              </div>
            </div>

            <div className="d-flex flex-column pt-2 w-100">
              <Filters
                countries={this.state.countries}
                cities={this.state.cities}
                selectHandle={this.handleFilterData}
                country={country}
                city={city}
                handleDateChange={this.handleDateRange}
                groupByselectData={this.state.selectData}
                groupByselectHandler={this.selectHandler}
                groupByselected={this.state.selected}
              ></Filters>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-2 ml-auto">
              <div className="d-flex align-items-center">
                Currency:
                <div
                  style={{
                    marginLeft: "5px",
                    padding: "2px 18px 2px 12px",
                  }}
                  className="card"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  {this.state.currencyData}
                </div>
                <Menu
                  style={{
                    minHeight: "300px",
                    maxHeight: "300px",
                  }}
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <div className="currency-hover-class">
                    <MenuItem>
                      Currency:
                      <Input
                        id=""
                        className="filterCurrency"
                        {...this.state.currencyFilter.input}
                        onChange={(e) => this.filterCurrency(e)}
                      />
                    </MenuItem>
                    {this.state.filterData.map((k, index) => (
                      <MenuItem
                        key={index}
                        className="menuItem"
                        onClick={() => this.getCurrencyData(k)}
                      >
                        <span>
                          <img
                            src={k.flag}
                            height={25}
                            width={25}
                            alt="currency"
                          />
                        </span>
                        <span>^</span>
                        <span className="pl-3 currencySymbol">
                          {k.currencies[0].symbol}
                        </span>
                        <span className="pl-3">{k.name}</span>
                      </MenuItem>
                    ))}
                  </div>
                </Menu>
              </div>

              <div className="d-flex justify-content-end align-items-center ml-3">
                <span
                  className="mr-2 ml-3"
                  // style={{ marginLeft: "20px" }}
                >
                  Device:
                </span>

                <div className="mr-2 globalFontSize">
                  <ReactSelect
                    className={"custom-z-index"}
                    city={this.state.sortByDevice}
                    change={this.sortSelectHandler}
                    selected={this.state.selectedSortByDevice}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="activeBarGraph d-flex align-items-center justify-content-center"
            style={{ padding: 0, marginTop: "20px" }}
          >
            {this.state.loader ? (
              <h4 style={{ color: "grey" }}>
                {/* <CircularProgress /> */}
                Loading data...
              </h4>
            ) : this.state.dataLoaded ? (
              <div
                style={{
                  height: "100%",
                  padding: "10px 0px 10px 0px",
                  width: "100%",
                }}
              >
                <Graph config={this.state.graphData} />
              </div>
            ) : (
              <h4 style={{ color: "grey" }}>No data for selected date range</h4>
            )}
          </div>

          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              marginTop: "50px",
              backgroundColor: "white",
              borderRadius: "3px",
              minHeight: "100px",
              boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
            }}
          >
            {this.state.loader ? (
              <h4 style={{ color: "grey" }}>Loading data...</h4>
            ) : this.state.dataLoaded ? (
              <TableComponent
                tableData={this.state.tableData}
                currencyData={this.state.currencyData}
              />
            ) : (
              <h4 style={{ color: "grey" }}>No data for selected date range</h4>
            )}
          </div>

          {/* Export Modal will only open when user click on export button then only this modal will to ask for export and cancel */}
          <Modal
            isOpen={this.state.isExport}
            toggle={() =>
              this.setState({
                isExport: !this.state.isExport,
              })
            }
            width={"35%"}
          >
            <div className="col-12 px-0">
              <div className="py-3 reportModal-header pl-3 border-bottom">
                Export your Table Data
              </div>
              <div className="py-3 reportModal-subText pl-3 border-bottom">
                Table will be exported as a CSV (comma separated values).
              </div>
              <div className="py-3 col-12">
                <div className="d-flex justify-content-end">
                  <Pbutton
                    onClick={() =>
                      this.setState({
                        isExport: !this.state.isExport,
                      })
                    }
                    className="reportModal-cancelBtn"
                  >
                    Cancel
                  </Pbutton>
                  <CSVLink
                    onClick={() =>
                      this.setState({
                        isExport: !this.state.isExport,
                      })
                    }
                    data={this.state.logsData}
                    filename={"my-file.csv"}
                    className="reportModal-exportBtn"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal>
          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackBarMessage}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(RideSurge);
