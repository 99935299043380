import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Row from "./row";
// import { makeStyles } from '@material-ui/core/styles';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
// import '../../index.css'

class TableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      open: false,
      page: 0,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
    });
  };

  render() {
    const { tableData } = this.props;
    const { open } = this.state;

    return (
   
      <div style={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead style={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell style={{ fontWeight: 700, fontSize: "16px" }}>
                  Month
                </TableCell>

                <TableCell
                  style={{ fontWeight: 700, fontSize: "16px" }}
                  align="right"
                >
                  Count
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
                .map((row, index) => (
                  <Row row={row} collaps={open} key={index} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ backgroundColor: "white" }}
          rowsPerPageOptions={[5, 10, 15, 20]}
          id="backgroundShadow"
          component="div"
          // SelectProps={{
          //   inputProps: { 'class': 'customClass' },
          //   native: true,
          //   // cursor:"pointer",
          // }}
          count={tableData.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
  
    );
  }
}

export default TableComponent;
