import React, { Component } from "react";
import TopRides from "./RideType";
import RideLocation from "./RideLocation";

class TopPickupDropLocation extends Component {
  render() {
    const { data, graphConfig, loader } = this.props;
    return (
      <React.Fragment>
        <div className="top_ride">
          <div className="card ride_loc">
            <TopRides
              carData={data}
              loader={loader}
              currency={this.props.currencyData}
            />
          </div>

          <div
            className="card ride_loc"
            style={
              {
                // padding: "15px 15px 0px 0px",
              }
            }
          >
            <RideLocation
              graph_height={graphConfig.top}
              loader={loader}
              options={graphConfig.topPickup.options}
              series={graphConfig.topPickup.series}
            />
          </div>

          <div
            className="card ride_loc"
            style={
              {
                // padding: "15px 15px 0px 0px",
              }
            }
          >
            <RideLocation
              graph_height={graphConfig.top}
              loader={loader}
              options={graphConfig.topDrop.options}
              series={graphConfig.topDrop.series}
            />
          </div>
        </div>
        <style>{`
        .top_ride{
          min-width: 300px;
          margin: 15px 5px 5px 5px;
          width: 32%;

        }
        .ride_loc{
          width: 100%;
          // top: 20px;
          margin-bottom: 10px;
          // padding: 15px 0px
        }

      `}</style>
      </React.Fragment>
    );
  }
}

export default TopPickupDropLocation;
