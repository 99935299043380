import { getForReport } from "../lib/request";
// import moment from "moment";
// import jstz from "jstz";
// import axios from "axios";

// var timezone = jstz.determine();
// let yourTimeZone = timezone.name();

//Ride Count
export const DashboardRideCount = (
  start_time,
  end_time,
  group_by,
  status,
  device,
  country_id,
  city_id,
  zone_id
) => {
  let URL = `/dashboard/ride/count?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&status=${status}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

//Ride Status
export const DashboardRideStatus = (
  start_time,
  end_time,
  group_by,
  status,
  device,
  country_id,
  city_id,
  zone_id
) => {
  let URL = `/dashboard/ride/status?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&status=${status}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

//Ride Payment Activity
export const RidePaymentActivity = (
  start_time,
  end_time,
  group_by,
  status,
  device,
  country_id,
  city_id,
  zone_id
) => {
  let URL = `/dashboard/ride/payment?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&status=${status}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

//Ride Revenue
export const RideRevenueOverview = (
  start_time,
  end_time,
  group_by,
  // status,
  currency,
  device,
  country_id,
  city_id,
  zone_id
) => {
  // let URL = `/dashboard/ride/revenue?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&status=${status}`;
  let URL = `/dashboard/ride/revenue?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&status=12&currency=${currency}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};
