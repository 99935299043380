// Dependency Imports
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Custom Imports
import "../../static/scss/App.scss";
import Header from "../../components/header/header";
//icons
import PollIcon from "@material-ui/icons/Poll";
//helper function
import {
  Route,
  withRouter,
  // BrowserRouter as Router,
  // Redirect,
} from "react-router-dom";

//nested routes
// import ProductPerformance from "./ProductPerformance";
import RideCount from "./RideAnalytics/RideCount";
import TopRideLocation from "./RideAnalytics/TopRideLocation";
import RidePaymentActivity from "./RideAnalytics/RidePaymentActivity";
import RideRevenue from "./RideAnalytics/RideRevenue/RideRevenue";
import RideStatus from "./RideAnalytics/RideCancelStatus";
import Dashboard from "./Dashboard/index";
import RideHeatMap from "./HeatMap";
import RideSurge from "./RideAnalytics/RideSurge";

class LandingIndex extends React.Component {
  state = {
    cartLogs: " ",
    ProductRecentView: " ",
    FavProduct: " ",
    sidePanel: [
      // {
      //   name: "Product performance",
      //   url: `${this.props.match.url}/product-performance`,
      //   icon: PollIcon,
      //   component: ProductPerformance,
      // },

      {
        name: "Rides Analytics Overview",
        url: `${this.props.match.url}/rides-analytics-overview`,
        icon: PollIcon,
        component: Dashboard,
      },

      {
        name: "Ride Count",
        url: `${this.props.match.url}/ride-count`,
        icon: PollIcon,
        component: RideCount,
      },

      {
        name: "Ride Top Location",
        url: `${this.props.match.url}/ride-top-location`,
        icon: PollIcon,
        component: TopRideLocation,
      },
      {
        name: "Ride Payment Activity",
        url: `${this.props.match.url}/ride-payment-activity`,
        icon: PollIcon,
        component: RidePaymentActivity,
      },
      {
        name: "Ride Revenue",
        url: `${this.props.match.url}/ride-revenue`,
        icon: PollIcon,
        component: RideRevenue,
      },
      {
        name: "Ride Cancel Status",
        url: `${this.props.match.url}/ride-cancel-status`,
        icon: PollIcon,
        component: RideStatus,
      },
      {
        name: "Ride Heat Map",
        url: `${this.props.match.url}/ride-heat-map`,
        icon: PollIcon,
        component: RideHeatMap,
      },

      {
        name: "Ride Surge",
        url: `${this.props.match.url}/ride-surge`,
        icon: PollIcon,
        component: RideSurge,
      },
    ],
  };

  render() {
    if (this.props.location.pathname === "/") {
      this.props.history.push("/dashbord/rides-analytics-overview");
    }
    return (
      <div className="dash-board">
        <Header
          title="shoppd - Dashboard"
          data={this.state.sidePanel}
          url={`${this.props.match.url}/rides-analytics-overview`}
        ></Header>

        <div
          className="dashbord-body"
          // style={{ height: "100vh", overflow: "auto" }}
        >
          {/* <SidePanel data={this.state.sidePanel}></SidePanel> */}

          <div className="">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  exact
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    index: state.index,
  };
};

export default connect(mapStateToProps, null)(withRouter(LandingIndex));
