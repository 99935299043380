import React, { Component } from "react";
import Chart from "react-apexcharts";

class RideLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noData: false,
      noDataText: "Loading data...",
      loader: true,
    };
  }

  valueFormatter = (value) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      let isDecimal = kValue % 1;
      if (isDecimal) {
        return kValue.toFixed(0) + " K";
      }
      return kValue.toFixed(0) + " K";
    }
    if (value >= 100000) {
      let kValue = value / 1000000;

      return kValue.toFixed(2) + " M";
    }
    return Number(value.toFixed(0));
  };

  render() {
    const graph_height = this.props.graph_height;
    return (
      <React.Fragment>
        {this.state.noData ? (
          <div className="no-data">
            <div>{this.state.noDataText}</div>
          </div>
        ) : (
          <Chart
            options={this.props.options}
            series={this.props.series}
            type="bar"
            width="100%"
            height={graph_height.value > 3 ? 280 : 200}
          />
        )}
        <style>
          {`.no-data{
                height: 230px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                font-weight: 500;
                color: grey;
          }`}
        </style>
      </React.Fragment>
    );
  }
}

export default RideLocation;
