import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";
import ApexCharts from "react-apexcharts";
import getSymbolFromCurrency from "currency-symbol-map";

/**
 * @Author Jai
 * @Date 8 April, 2021
 * @Description Created this graph page for getting the status of Ride Revenue and we are using this page on Ride Analytics Overview.
 */

class rideRevenueOverview extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Ride Revenue
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`ride-revenue`}>View Activity</Link>
          </span>
        </div>
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue globalFontSize">
            {this.props.loader ? (
              <RevLoader />
            ) : this.props.rideRevenueCount ? (
              `${getSymbolFromCurrency(this.props.currency)} ${
                this.props.rideRevenueCount
              }`
            ) : (
              `${getSymbolFromCurrency(this.props.currency)} ` + 0
              // 0
            )}
          </span>
        </div>

        <div className="chart">
          <ApexCharts
            options={this.props.config.options}
            series={this.props.config.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default rideRevenueOverview;
