import React, { Component } from "react";
import Apexcharts from "react-apexcharts";
import SubGraph from "./SubGraph";

class index extends Component {
  render() {
    return (
      <div className="overviewBody">
        {this.props.loader ? (
          <div
            className="card d-flex justify-content-center align-items-center"
            style={{
              marginTop: "40px",
              minHeight: "400px",
            }}
          >
            <h4 style={{ color: "grey" }}>Loading Data ...</h4>
          </div>
        ) : (
          this.props.selected.label !== "None" &&
          this.props.overviewData.map((item, index) => (
            <div className="" key={index}>
              <div className="card mt-2">
                <div
                  className="m-3 mt-4 graphTitle"
                  style={{ fontSize: "14px" }}
                >
                  <SubGraph
                    totalSale={this.props.revenue.today_total || 0}
                    previousMonthRevenue={
                      this.props.revenue.previous_period || 0
                    }
                    currentMonthRevenue={this.props.revenue.current_period || 0}
                    revenueGrowthStatus={this.props.revenue.percentage || 0}
                    revenueMessage={this.props.data || ""}
                    currency={this.props.currencyData}
                  />
                </div>
                <div className="chart" style={{ paddingRight: "15px" }}>
                  {this.props.config &&
                  this.props.config.series.length &&
                  this.props.loader === false ? (
                    <Apexcharts
                      series={this.props.config.series}
                      options={this.props.config.options}
                      type="line"
                      height={380}
                    />
                  ) : (
                    <div
                      style={{
                        height: "350px",
                        textAlign: "center",
                        paddingTop: "140px",
                      }}
                    >
                      <h4 style={{ color: "gray" }}>
                        No data for selected date range
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }
}

export default index;
