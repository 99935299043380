let config = {
  options: {
    responsive: [
      {
        breakpoint: undefined,
        options: {},
      },
    ],

    chart: {
      toolbar: {
        show: false,
      },
    },

    noData: {
      text: undefined,
      align: "center",
      verticalAlign: "middle",
    },

    dataLabels: {
      enabled: false,
    },
    subtitle: {
      text: "Top 5 Pickup Location for yellow Taxi",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "25px",
        fontWeight: "normal",
        fontFamily: undefined,
        color: "",
      },
    },
    yaxis: {
      // tickAmount: 6,
      // labels: {
      //   formatter: (value) => {
      //     return val;
      //   },
      // },
    },

    title: {
      text: undefined,
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "100%",
        barHeight: "60%",
      },
      theme: {
        monochrome: {
          enabled: true,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
    },
    xaxis: {
      categories: [
        "Upper East Side South",
        "Midtown center",
        "Upper East Side North",
        "Midtown East",
        "Penn Station Madisco",
      ],
      labels: {
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },

        // formatter: (value) => {
        //   return val;
        // },
      },
    },
  },

  series: [
    {
      data: [1400, 430, 448, 470, 12540],
    },
  ],
};
export { config };
