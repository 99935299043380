import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import moment from "moment";

import "../../../../static/scss/globalComponent.scss";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { countries } from "../../../../fixtures/dummyApi/countries";

import RideLocation from "./RideConfig/index";
import { fetchTopRides } from "../../../../services/TopRides";
import { config } from "./RideConfig/graphConfig/config";
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "../../../../components/snackbar/snackbar";
// import ls from "local-storage";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "../../../../components/InputBox/Input";
//heper
import { tooltipFormatter } from " ../../../src/lib/helper";

import {
  getCities,
  getCountries,
} from "../../../../services/rideLocationFilter";
import _ from "lodash";
import Filters from "../../../../components/filter/location/filter";

const styles = (theme) => ({
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    marginLeft: "10px",
  },
});

class TopRideLocation extends Component {
  myRef = React.createRef();
  state = {
    snackBarMessage: "",
    showSnackbar: false,

    //currency
    anchorEl: null,
    currencyData: localStorage.getItem("currency")
      ? JSON.parse(localStorage.getItem("currency"))
      : "INR",
    previousCurrencyData: localStorage.getItem("currency")
      ? JSON.parse(localStorage.getItem("currency"))
      : "INR",

    // currencyData: window._env_.CURRENCY,
    // previousCurrencyData: window._env_.CURRENCY,

    filterData: [...countries],
    currencyFilter: {
      label: "Currency filter",
      input: {
        name: "currencyFilter",
        type: "text",
        value: "",
      },
    },
    //sortBy
    sortBy: 1,
    storeIdValue: "0",
    sortByList: [
      { label: "Trip Count", value: 1 },
      { label: "Trip Revenue", value: 2 },
    ],
    selectedSortBy: { label: "Trip Revenue", value: 2 },

    sortByDevice: [
      { label: "All", value: 0 },
      { label: "Android", value: 1 },
      { label: "iOS", value: 2 },
    ],
    selectedSortByDevice: { label: "All", value: 0 },

    sortByTopLocation: [
      { label: "Top 3", value: 3 },
      { label: "Top 5", value: 5 },
    ],
    selectedSortByTopLocation: { label: "Top 5", value: 5 },

    // top: 3,
    isExport: false,
    carData: [],
    config: config,
    topNPickups: config,
    topNDrops: config,

    loader: true, //loader state
    noDataText: "Loading Data ...",

    country: "",
    city: "",
    countryId: "",
    cityId: "",
    countries: [],
    cities: [],

    startDate:
      localStorage.getItem("startDate") != null
        ? localStorage.getItem("startDate")
        : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate:
      localStorage.getItem("endDate") != null
        ? localStorage.getItem("endDate")
        : moment().format("YYYY-MM-DD HH:mm:ss"),
    // startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    // endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  };

  componentDidMount() {
    this.handleFilterData("", "initialCall");

    this.getData(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedSortBy.value,
      this.state.selectedSortByDevice.value,
      this.state.selectedSortByTopLocation.value,
      this.state.currencyData
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      this.state.currencyData !== prevState.currencyData ||
      prevState.selectedSortBy.value !== this.state.selectedSortBy.value ||
      prevState.selectedSortByDevice.value !==
        this.state.selectedSortByDevice.value ||
      prevState.selectedSortByTopLocation.value !==
        this.state.selectedSortByTopLocation.value ||
      this.state.countryId !== prevState.countryId ||
      this.state.cityId !== prevState.cityId
    ) {
      // setInLocalStorage("currency", this.state.currencyData);
      this.setState({
        config: config,
        tableLoader: true,
        isDisplayChart: false,
        noDataText: "Loading Data ...",
        loader: true,
      });
      this.getData(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedSortBy.value,
        this.state.selectedSortByDevice.value,
        this.state.selectedSortByTopLocation.value,
        this.state.currencyData,
        this.state.countryId,
        this.state.cityId,
      );
    }
    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],
      });
    }
  }

  getData = (
    start_timestamp,
    end_timestamp,
    sortBy,
    device,
    top,
    currency,
    countryId,
    cityId,
  ) => {
    fetchTopRides(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      sortBy || 2,
      device,
      top || 3,
      currency,
      countryId || "",
      cityId || "",
    )
      .then((response) => {
        const carData = [...response.data.data];
        let graph = {};
        carData.map((data) => {
          let carName = data.typeName;
          let pickupCategories = data.topNPickups.graph.xaxis.categories;
          let topPickup = {
            series: [...data.topNPickups.graph.series],
            options: {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  borderRadius: 0,
                },
              },
              title: {
                text: `Top Pickup Location for ${carName}`,
                offsetX: 10,
                offsetY: 10,
                style: {
                  color: "#6a747d",
                  fontSize: "18px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
              dataLabels: { enabled: false },

              theme: {
                mode: "light",
                palette: "palette2",
                monochrome: {
                  enabled: true,
                  color: "#4CAF50",
                  shadeTo: "light",
                  shadeIntensity: 0.65,
                },
              },

              xaxis: {
                categories: pickupCategories,
                labels: {
                  formatter: (val, index) => {
                    return tooltipFormatter(val, this.state.currencyData, false);
                  },
                  show: true,
                  rotate: -45,
                  trim: true,
                },
                title: {
                  text: data.topNPickups.graph.xaxis.title,
                  style: {
                    fontSize: "11px",
                    fontWeight: 700,
                  },
                },
                tooltip: {
                  enabled: false,
                },
              },

              // noData: { ...topPickup.options.noData, text: "" },
              yaxis: {
                title: {
                  text: data.topNPickups.graph.yaxis.title,
                  style: {
                    fontSize: "11px",
                    fontWeight: 700,
                  },
                },
                show: true,
                labels: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  style: { fontSize: "11px" },
                },
                axisTicks: {
                  show: false,
                },
              },

              tooltip: {
                enabled: true,
                followCursor: true,
                shared: true,

                y: {
                  formatter: (val, series) => {
                    return tooltipFormatter(
                      val,
                      this.state.currencyData,
                      this.state.selectedSortBy.value === 2 ? true : false
                    );
                  },

                  title: {
                    formatter: (seriesName) => seriesName,
                  },
                },
              },
            },
          };

          let dropCategories = data.topNDrops.graph.xaxis.categories;
          let topDrop = {
            series: [...data.topNDrops.graph.series],
            options: {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  borderRadius: 0,
                },
              },

              title: {
                text: `Top Drop Location for ${carName}`,
                offsetX: 10,
                offsetY: 10,
                style: {
                  color: "#6a747d",
                  fontSize: "18px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                  cssClass: "apexcharts-yaxis-title",
                },
              },

              theme: {
                mode: "light",
                palette: "palette7",
                monochrome: {
                  enabled: true,
                  color: "#E2C044",
                  shadeTo: "light",
                  shadeIntensity: 0.65,
                },
              },

              dataLabels: {
                enabled: false,
              },

              xaxis: {
                categories: dropCategories,
                labels: {
                  show: true,
                  formatter: (val, index) => {
                    return tooltipFormatter(val, this.state.currencyData, false);
                  },
                  rotate: -45,
                  trim: true, // if (this.state.selectedSortBy.value == 2) {
                  //   return tooltipFormatter(
                  //     val,
                  //     this.state.currencyData,
                  //     true
                  //   );
                  // }
                },
                title: {
                  text: data.topNPickups.graph.xaxis.title,
                  style: {
                    fontSize: "11px",
                    fontWeight: 700,
                    // paddingRight: "20px",
                  },
                },
                tooltip: {
                  enabled: false,
                },
              },
              // noData: { ...topDrop.options.noDatfa, text: "" },
              yaxis: {
                title: {
                  text: data.topNPickups.graph.yaxis.title,
                  style: {
                    fontSize: "11px",
                    fontWeight: 700,
                  },
                },
                show: true,
                labels: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  style: { fontSize: "11px" },
                },

                axisTicks: {
                  show: false,
                },
              },
              tooltip: {
                enabled: true,
                followCursor: true,
                shared: true,
                y: {
                  formatter: (val, series) => {
                    return tooltipFormatter(
                      val,
                      this.state.currencyData,
                      this.state.selectedSortBy.value === 2 ? true : false
                    );
                  },

                  title: {
                    formatter: (seriesName) => seriesName,
                  },
                },
              },
            },
          };
          let top = this.state.selectedSortByTopLocation;
          graph[data.typeId] = { topPickup, topDrop, top };
        });
        this.setState({
          carData: carData,
          config: graph,
          loader: false,
          previousCurrencyData: this.state.currencyData,
        });
      })
      .catch((error) => {
        if (!this.state.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Please select a date range",
            currencyData: this.state.previousCurrencyData,
          });
        } else if (error && error.data && error.data.status === 204) {
          this.setState({
            showSnackbar: true,
            noData: true,
            loader: false,
            snackBarMessage: "Data Not Found For the Given Date Range!",
          });
        } else if (error && error.data && error.data.status === 500) {
          this.setState({
            showSnackbar: true,
            noData: true,
            loader: false,
            snackBarMessage: "Internal Server Error!",
          });
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            config: {
              ...config,
              options: {
                ...config.options,
                xaxis: {
                  categories: [],
                },
                noData: {
                  ...config.options.noData,
                  text: "No data found for the selected date range",
                },
              },
            },

            tableData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
            loader: false,
            currencyData: this.state.previousCurrencyData,
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            tableLoader: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };

  //sortBy
  dropDownSelect = (e) => {
    if (Number.isInteger(e)) {
      this.setState({ sortBy: e });
    } else {
      this.setState({
        storeIdValue: e.target.value,
      });
    }
  };

  sortSelectHandler = (option) => {
    if (
      option.label === "All" ||
      option.label === "Android" ||
      option.label === "iOS"
    ) {
      this.setState({
        selectedSortByDevice: option,
      });
    }

    if (option.label === "Top 3" || option.label === "Top 5") {
      this.setState({
        selectedSortByTopLocation: option,
      });
    }

    if (option.label === "Trip Count" || option.label === "Trip Revenue") {
      this.setState({
        selectedSortBy: option,
      });
    }
  };

  //currency
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });
    // let filterData = countries.filter((item, index) => {
    //   return (
    //     item.alpha3Code.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
    //       -1 ||
    //     item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    //   );
    // });
    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase()) )  
       !==
          -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      filterData,
    });
  };

  getCurrencyData = (obj) => {
    this.setState({
      currencyData: obj.currencies[0].code,
    });
    this.handleClose();
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
      }
      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  handleDateChange = (startDate, endDate) => {
    if (startDate !== this.state.startDate || endDate !== this.state.endDate) {
      this.setState({
        startDate: startDate,
        endDate: endDate,

        dataLoaded: false,
        loadingScreen: true,
      });
    }
  };

  render() {
    const { carData, config } = this.state;
    const { classes } = this.props;
    const { country, city } = this.state;

    //sortBy
    // let storeId = ls.get("store_id");
    return (
      <React.Fragment>
        <div
          className="containerDiv p-0"
          style={{ left: "0px" }}
          ref={this.myRef}
        >
          <div className="bg-white border p-2">
            <div
              className="hoverPathClass globalFontSize"
              onClick={() =>
                this.props.history.push("rides-analytics-overview")
              }
            >
              <i className="fas fa-angle-left mr-2"></i>Reports
            </div>
            <div className="title">Top Ride Location</div>
            <div
              className="d-flex text-grey mt-2 globalFontSize"
              style={{ marginBottom: "" }}
            >
              <div className="mr-3 db_ptr">
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <i className="fas fa-print mr-1"></i>Print
                    </span>
                  )}
                  content={() => this.myRef.current}
                />
              </div>
              <div
                className="mr-3 db_ptr"
                onClick={() =>
                  this.setState({ isExport: !this.state.isExport })
                }
              >
                <i className="fas fa-download mr-1"></i>Export
              </div>
            </div>

            <div className="d-flex flex-column pt-2 w-100">
              <Filters
                countries={this.state.countries}
                cities={this.state.cities}
                selectHandle={this.handleFilterData}
                country={country}
                city={city}
                hideGroupBy={true}
                handleDateChange={this.handleDateChange}
              ></Filters>
            </div>

            <div className="d-flex align-items-center mt-3">
              <div className=" d-flex align-items-center">
                Currency:
                <div
                  className="card"
                  style={{ marginLeft: "5px", padding: "2px 18px 2px 12px" }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  {this.state.currencyData}
                </div>
                <Menu
                  style={{ minHeight: "300px", maxHeight: "300px" }}
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <div className="currency-hover-class">
                    <MenuItem>
                      Currency:
                      <Input
                        id=""
                        className="filterCurrency"
                        {...this.state.currencyFilter.input}
                        onChange={(e) => this.filterCurrency(e)}
                      ></Input>
                    </MenuItem>
                    {this.state.filterData.map((k, index) => (
                      <MenuItem
                        key={index}
                        className={classes.menuItem}
                        onClick={() => this.getCurrencyData(k)}
                      >
                        <span>
                          <img
                            src={k.flag}
                            height={25}
                            width={25}
                            alt="currency"
                          />
                        </span>
                        <span>^</span>
                        <span className="pl-3 currencySymbol">
                          {k.currencies[0].symbol}
                        </span>
                        <span className="pl-3">{k.name}</span>
                      </MenuItem>
                    ))}
                  </div>
                </Menu>
              </div>
              <span className="mr-2  ml-3">Sort By:</span>

              <div className="mr-2 globalFontSize">
                <ReactSelect
                  city={this.state.sortByList}
                  change={this.sortSelectHandler}
                  selected={this.state.selectedSortBy}
                />
              </div>

              <span className="mr-2  ml-auto">Device:</span>

              <div className="mr-2 globalFontSize">
                <ReactSelect
                  city={this.state.sortByDevice}
                  change={this.sortSelectHandler}
                  selected={this.state.selectedSortByDevice}
                />
              </div>

              <span className="mr-2  ml-3">Ride:</span>

              <div className="mr-2 globalFontSize">
                <ReactSelect
                  city={this.state.sortByTopLocation}
                  change={this.sortSelectHandler}
                  selected={this.state.selectedSortByTopLocation}
                />
              </div>
            </div>
          </div>

          <div
            className={
              carData.length <= 1
                ? "row justify-content-start ml-2"
                : "row justify-content-center"
            }
          >
            {this.state.loader ? (
              // <div className="ml-3 w-100">
              // <div className="card" style={{ marginTop: "10px" }}>
              <div className="card card-body no-data p-0 mr-3 mt-2">
                <CircularProgress />
                Loading....
              </div>
            ) : // </div>
            // </div>
            !this.state.loader && carData && carData.length ? (
              carData.map((data, index) => {
                {
                  if (config[data.typeId]) {
                    return (
                      <RideLocation
                        data={data}
                        graphConfig={config[data.typeId]}
                        key={index}
                        currencyData={this.state.currencyData}
                        loader={this.state.loader}
                        sortByChangeHandler={(sortBy) =>
                          this.dropDownSelect(sortBy)
                        }
                      />
                    );
                  } else {
                    return (
                      // <div className="ml-3 w-100">
                      //   <div className="card" style={{ marginTop: "10px" }}>
                      <div className="card card-body no-data p-0 mr-3 mt-2">
                        No data found for selected graph
                      </div>
                      //   </div>
                      // </div>
                    );
                  }
                }
              })
            ) : !this.state.loader && (!carData || !carData.length) ? (
              // <div className="ml-3 w-100">
              //   <div className="card" style={{ marginTop: "10px" }}>
              <div className="card card-body no-data p-0 mr-3 mt-2">
                No data found for selected graph
              </div>
            ) : (
              //   </div>
              // </div>
              <div>"Somemissing"</div>
            )}
          </div>
          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackBarMessage}
          />
        </div>

        <style>{`
     
          .no-data{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px !important;
            background: white;
            font-weight: 500;
            color: grey;
            height: calc(100vh - 16rem)
          }        
        `}</style>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(TopRideLocation));

// export default TopRideLocation;
