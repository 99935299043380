import React, { Component } from "react";
import ApexChart from "react-apexcharts";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      sortByDevice: 3,
      redirect: false,
    };
  }

  render() {
    return (
      <div className="overviewBody">
        <ApexChart
          options={this.props.config.options}
          series={this.props.config.series}
          type="line"
          height={350}
        />
      </div>
    );
  }
}

export default Index;
