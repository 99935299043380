import React, { Component } from "react";
import "./index.scss";
import { tooltipFormatter } from "../../../../../../lib/helper";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        yaxis: {
          title: {
            text: "Sales over time",
          },
        },
      },
    };
  }

  render() {
    return (
      <div
        className="d-flex ml-3 flex-column graphContainer"
        style={{ fontWeight: "700" }}
      >
        <div className="d-flex flex-column w-100 subGraph">
          <div
            className="d-flex w-100 justify-content-between"
            style={{ paddingRight: "45px" }}
          >
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-center">
                <div className="">{`${tooltipFormatter(
                  this.props.totalSale,
                  this.props.currency,
                  true
                )}`}</div>
              </div>
              <span className="" style={{ fontSize: "smaller", color: "gray" }}>
                Today's Total Sales
              </span>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-center">
                <div className="">{`${
                  this.props.revenueGrowthStatus || 0
                }%`}</div>
                <div>
                  <i
                    style={{
                      color:
                        Math.sign(this.props.revenueGrowthStatus) >= 0
                          ? "green"
                          : "red",
                    }}
                    className={
                      Math.sign(this.props.revenueGrowthStatus) >= 0
                        ? "fas fa-long-arrow-alt-up ml-2"
                        : "fas fa-long-arrow-alt-down ml-2"
                    }
                  ></i>
                </div>
              </div>
              <span className="" style={{ fontSize: "smaller", color: "gray" }}>
                Revenue Growth
              </span>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-end pt-1">
            <div className="d-flex flex-column pr-4">
              <div className="d-flex justify-content-center">
                <div className="">{`${tooltipFormatter(
                  this.props.previousMonthRevenue,
                  this.props.currency,
                  true
                )}`}</div>
              </div>
              <span className="" style={{ fontSize: "smaller", color: "gray" }}>
                Previous Period
              </span>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-center">
                <div className="">{`${tooltipFormatter(
                  this.props.currentMonthRevenue,
                  this.props.currency,
                  true
                )}`}</div>
              </div>
              <span className="" style={{ fontSize: "smaller", color: "gray" }}>
                Current Period
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
