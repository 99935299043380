import React, { Component } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

// MUI ICONS
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    const { open } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <TableRow className={""}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            style={{ fontWeight: 550, fontSize: "13px" }}
          >
            {row.timeStamp}
          </TableCell>
          <TableCell
            align="right"
            style={{ fontWeight: 550, fontSize: "13px" }}
          >
            {row.customer_cancelled}
          </TableCell>
          {/* <TableCell
            align="right"
            style={{ fontWeight: 550, fontSize: "13px" }}
          >
            {row.driver_cancelled}
          </TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse
              in={this.props.collaps ? true : open}
              timeout="auto"
              unmountOnExit
            >
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Vehicle Type</TableCell>
                      <TableCell />
                      <TableCell align="right">Customer Cancelled</TableCell>
                      {/* <TableCell align="right">Driver Cancelled</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow, index) => (
                      <TableRow key={`id${index}`}>
                        <TableCell component="th" scope="row">
                          {historyRow["Vehicle Type"]}
                        </TableCell>
                        <TableCell />
                        <TableCell align="right">
                          {historyRow.customer_cancelled}
                        </TableCell>
                        {/* <TableCell align="right">
                          {historyRow.driver_cancelled}
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

export default Row;
