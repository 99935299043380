import React from "react";
import "./rideType.css";
import { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { IMAGES } from "../../../../../../config/app";
import { tooltipFormatter } from "../../../../../../lib/helper";

class RideType extends Component {
  state = {
    noData: false,
    noDataText: "Loading data...",
    loader: true,
  };

  render() {
    const { carData } = this.props;
    return (
      <React.Fragment>
        <div className="heading">
          <ul style={{ height: "23px" }}>
            <li>
              <p className="sub-heading">
                Most Popular Pickup and Drop Location for {carData.typeName}
              </p>
            </li>
          </ul>
        </div>
        {carData.totalTrip ? (
          <div className="d-flex container-fluid p-0" style={{ width: "100%" }}>
            <div className="ridewidth" style={{ width: "40%" }}>
              <div className="city-heading">{carData.topCityName}</div>
              <div className="topLocation">{carData.topPickupZone}</div>
              <strong style={{ fontSize: "12px" }}>
                Number of trip: {carData.totalTrip}
              </strong>
              <br />
              <strong style={{ fontSize: "12px" }}>
                {/* Total Fare: {carData.totalFare.toFixed(2)} */}
                {`${tooltipFormatter(
                  carData.totalFare.toFixed(2),
                  this.props.currency,
                  true
                )}`}
              </strong>
            </div>
            <div
              className="ridewidth"
              style={{ width: "20%", padding: "20px 0px 0px 0px" }}
            >
              <img
                style={{ height: "60px", width: "80px" }}
                // src={IMAGES[carData.typeName]}
                src={carData.vehicleImgOff}
                alt="Logo"
              />
            </div>
            <div className="ridewidth text-right" style={{ width: "40%" }}>
              <div className="city-heading">{carData.topCityName}</div>
              <div className="topLocation">{carData.topDropZone}</div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center carSpinner">
            <CircularProgress />
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default RideType;
